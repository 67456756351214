import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isStatisticPage: false,
  isLoading: false,
  chartDonutData: [
    { lable: "ปลั๊กไฟอัจฉริยะ 1", value: 0 },
    { lable: "ปลั๊กไฟอัจฉริยะ 2", value: 0 },
    { lable: "ปลั๊กไฟอัจฉริยะ 3", value: 0 },
    { lable: "ปลั๊กไฟอัจฉริยะ 4", value: 0 },
    { lable: "หลอดไฟอัจฉริยะ 1-3", value: 0 },
    { lable: "เครื่องปรับอากาศ 1", value: 0 },
    { lable: "เครื่องปรับอากาศ 2", value: 0 },
    { lable: "เครื่องปรับอากาศ 3", value: 0 },
    { lable: "เครื่องใช้ไฟฟ้าอื่นๆ", value: 0 },
  ],
  chartDonutColors: [
    "rgba(0, 112, 237, 1)",
    "rgba(110, 63, 190, 1)",
    "rgba(222, 234, 247, 1)",
    "rgba(17, 41, 60, 1)",
    "rgba(104, 0, 237, 1)",
    "rgba(255, 126, 49, 1)",
    "rgba(237, 71, 19, 1)",
    "rgba(237, 0, 185, 1)",
    "rgba(120, 133, 146, 1)",
  ],
  chartTimeLabels: [
    "00.00",
    "01.00",
    "02.00",
    "03.00",
    "04.00",
    "05.00",
    "06.00",
    "07.00",
    "08.00",
    "09.00",
    "10.00",
    "11.00",
    "12.00",
    "13.00",
    "14.00",
    "15.00",
    "16.00",
    "17.00",
    "18.00",
    "19.00",
    "20.00",
    "21.00",
    "22.00",
    "23.00",
  ],
  chartDataA: {
    name: "การผลิตไฟฟ้ารวมวันนี้",
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  },
  chartDataB: {
    name: "การใช้ไฟฟ้ารวมวันนี้",
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  },
};

const statictisDataSlice = createSlice({
  name: "statictisDataSlice",
  initialState,
  reducers: {
    setStateStatisticData: (state) => {
      return { ...state, isLoading: true };
    },
    getStatictisData: (state, action) => {
      // console.log("==========action.payload.result");
      // console.log(action.payload.result.chartDonutData);
      const chartDataA = {
        name: "การผลิตไฟฟ้ารวมวันนี้",
        data: action.payload.result.chartDataA,
      };
      const chartDataB = {
        name: "การใช้ไฟฟ้ารวมวันนี้",
        data: action.payload.result.chartDataB,
      };
      return {
        ...state,
        isLoading: false,
        chartDataA: chartDataA,
        chartDataB: chartDataB,
        chartDonutData: action.payload.result.chartDonutData,
      };
    },
  },
});

export const { setStateStatisticData, getStatictisData } =
  statictisDataSlice.actions;

export default statictisDataSlice.reducer;
