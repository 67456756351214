import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  schools: [],
  selectSchoolID: 0,
};

const initialDataSlice = createSlice({
  name: "initialDataSlice",
  initialState,
  reducers: {
    setInitialStateData: (state) => {
      return { ...state, isLoading: true };
    },
    setStateSchoolsData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        schools: action.payload,
      };
    },
    setSchoolIDData: (state, action) => {
      return {
        ...state,
        selectSchoolID: action.payload.selectSchoolID,
      };
    },
  },
});

export const { setInitialStateData, setStateSchoolsData, setSchoolIDData } =
  initialDataSlice.actions;

export default initialDataSlice.reducer;
