import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDashboardPage: false,
  isStatisticPage: false,
  isLoading: false,
  statusPower: 0,
  isDay: true,
  isMonth: false,
  isYear: false,

  smartLightPower: 0,
  smartLight1en: "OFF",
  smartLight2en: "OFF",
  smartLight3en: "OFF",

  smartPlug1Power: 0,
  smartPlug2Power: 0,
  smartPlug3Power: 0,
  smartPlug4Power: 0,
  smartPlug1en: "OFF",
  smartPlug2en: "OFF",
  smartPlug3en: "OFF",
  smartPlug4en: "OFF",

  solaCellPower: 0,
  powerConsumptionPower: 0,
  powerConsumptionPowerDay: 0,
  powerConsumptionPowerMonth: 0,
  powerConsumptionPowerYear: 0,
  solaCellPowerDay: 0,
  solaCellPowerMonth: 0,
  solaCellPowerYear: 0,

  smartAir1Power: 0,
  smartAir2Power: 0,
  smartAir3Power: 0,
  smartAir1MonitorOther: {
    Power: "off",
    Temp: 22.0,
    Mode: "cool",
    FanSpeed: "auto",
    HAMode: "off",
  },
  smartAir2MonitorOther: {
    Power: "off",
    Temp: 22.0,
    Mode: "cool",
    FanSpeed: "auto",
    HAMode: "off",
  },
  smartAir3MonitorOther: {
    Power: "off",
    Temp: 22.0,
    Mode: "cool",
    FanSpeed: "auto",
    HAMode: "off",
  },
  smartOtherPower: 0,
};

const dashboardDataSlice = createSlice({
  name: "dashboardDataSlice",
  initialState,
  reducers: {
    setStateData: (state) => {
      return { ...state, isLoading: true };
    },
    getDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        powerConsumptionPowerDay:
          action.payload.result.powerConsumption_Power_Day,
        powerConsumptionPowerMonth:
          action.payload.result.powerConsumption_Power_Month,
        powerConsumptionPowerYear:
          action.payload.result.powerConsumption_Power_Year,
        solaCellPowerDay: action.payload.result.solaCell_Power_Day,
        solaCellPowerMonth: action.payload.result.solaCell_Power_Month,
        solaCellPowerYear: action.payload.result.solaCell_Power_Year,
      };
    },
    setStateDayMonthYearData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isDay: action.payload.isDay,
        isMonth: action.payload.isMonth,
        isYear: action.payload.isYear,
      };
    },
    setStateSmartDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        statusPower: action.payload.data.status_power,
        solaCellPower: action.payload.data.solaCell_Power,
        powerConsumptionPower: action.payload.data.powerConsumption_Power,
        remaingElectric: action.payload.data.remaingElectric,
        inRoomElectric: action.payload.data.inRoomElectric,
        powerConsumptionPowerDay:
          action.payload.data.powerConsumption_Power_Day,
        powerConsumptionPowerMonth:
          action.payload.data.powerConsumption_Power_Month,
        powerConsumptionPowerYear:
          action.payload.data.powerConsumption_Power_Year,
        solaCellPowerDay: action.payload.data.solaCell_Power_Day,
        solaCellPowerMonth: action.payload.data.solaCell_Power_Month,
        solaCellPowerYear: action.payload.data.solaCell_Power_Year,
        smartOtherPower:
          action.payload.data.smartOther_Power === "null"
            ? 0
            : action.payload.data.smartOther_Power,
      };
    },
    setStateSmartLightPowerData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        smartLightPower: action.payload.data.smartLight_Power,
        smartLight1en: action.payload.data.smartLight1_en,
        smartLight2en: action.payload.data.smartLight2_en,
        smartLight3en: action.payload.data.smartLight3_en,
      };
    },
    setStateSmartAirPowerData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        smartAir1Power: action.payload.data.smartAir1_Power,
        smartAir2Power: action.payload.data.smartAir2_Power,
        smartAir3Power: action.payload.data.smartAir3_Power,
        smartAir1MonitorOther: action.payload.data.smartAir1_Monitor_other,
        smartAir2MonitorOther: action.payload.data.smartAir2_Monitor_other,
        smartAir3MonitorOther: action.payload.data.smartAir3_Monitor_other,
      };
    },
    setStateSmartPlugPowerData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        smartPlug1Power: action.payload.data.smartPlug1_Power,
        smartPlug2Power: action.payload.data.smartPlug2_Power,
        smartPlug3Power: action.payload.data.smartPlug3_Power,
        smartPlug4Power: action.payload.data.smartPlug4_Power,
        smartPlug1en: action.payload.data.smartPlug1_en,
        smartPlug2en: action.payload.data.smartPlug2_en,
        smartPlug3en: action.payload.data.smartPlug3_en,
        smartPlug4en: action.payload.data.smartPlug4_en,
      };
    },
    setStateControlHWData: (state, action) => {
      return { ...state, isLoading: false };
    },
    getSolaLoadDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        inRoomElectric: action.payload.inRoomElectric,
        powerConsumptionPower: action.payload.powerConsumption_Power,
        remaingElectric: action.payload.remaingElectric,
        smartOtherPower:
          action.payload.smartOther_Power === "null"
            ? 0
            : action.payload.smartOther_Power,
        solaCellPower: action.payload.solaCell_Power,
        statusPower: action.payload.status_power,
      };
    },
    getSmartLightDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        smartLightPower: action.payload.smartLight_Power,
        smartLight1en: action.payload.smartLight1_en,
        smartLight2en: action.payload.smartLight2_en,
        smartLight3en: action.payload.smartLight3_en,
      };
    },
    getSmartAirDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        smartAir1Power: action.payload.smartAir1_Power,
        smartAir2Power: action.payload.smartAir2_Power,
        smartAir3Power: action.payload.smartAir3_Power,
        smartAir1MonitorOther: action.payload.smartAir1_Monitor_other,
        smartAir2MonitorOther: action.payload.smartAir2_Monitor_other,
        smartAir3MonitorOther: action.payload.smartAir3_Monitor_other,
      };
    },
    getSmartPlugDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        smartPlug1Power: action.payload.smartPlug1_Power,
        smartPlug2Power: action.payload.smartPlug2_Power,
        smartPlug3Power: action.payload.smartPlug3_Power,
        smartPlug4Power: action.payload.smartPlug4_Power,
        smartPlug1en: action.payload.smartPlug1_en,
        smartPlug2en: action.payload.smartPlug2_en,
        smartPlug3en: action.payload.smartPlug3_en,
        smartPlug4en: action.payload.smartPlug4_en,
      };
    },
  },
});

export const {
  setStateData,
  getDashboardData,
  setStateDayMonthYearData,
  setStateSmartDashboardData,
  setStateSmartLightPowerData,
  setStateSmartAirPowerData,
  setStateSmartPlugPowerData,
  setStateControlHWData,
  getSolaLoadDashboardData,
  getSmartLightDashboardData,
  getSmartAirDashboardData,
  getSmartPlugDashboardData,
} = dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;
