import React from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const fontTheme = createTheme({
  typography: {
    fontFamily: `"Kanit", sans-serif`,
  },
});

const AuthLayout = ({ children }) => {
  return (
    <ThemeProvider theme={fontTheme}>
      <div className="app">
        <main className="content">
          <CssBaseline />
          {/* <Navigate to="/login" replace={true} /> */}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default AuthLayout;
