import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import ProtectedRoutes from "./ProtectedRoutes";
import LoadingIndicator from "./components/sss";
import Layout from "./components/layout/Layout";

// import Dashboard from "./scenes/dashboard";
// import Login from "./scenes/login";
// import SelectSchool from "./scenes/selectSchool";
// import Statistic from "./scenes/statistic";
// import Custumize from "./scenes/custumize";
// import UserSelectSchool from "./scenes/userSelectSchool";
// import UserProfile from "./scenes/userProfile";
// import NewUser from "./scenes/newuser";

const Dashboard = lazy(() => import("./scenes/dashboard"));
const Login = lazy(() => import( "./scenes/login"));
const SelectSchool = lazy(() => import( "./scenes/selectSchool"));
const Statistic = lazy(() => import( "./scenes/statistic"));
const Custumize = lazy(() => import( "./scenes/custumize"));
const UserSelectSchool = lazy(() => import( "./scenes/userSelectSchool"));
const UserProfile = lazy(() => import( "./scenes/userProfile"));
const NewUser = lazy(() => import( "./scenes/newuser"));


const App = ({ history }) => {
  return (
    <BrowserRouter history={history}>
      <Layout>
        <Routes>
          {/* Wrap lazy-loaded components with Suspense */}
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <ProtectedRoutes />
              </Suspense>
            }
          >
            {/* Nested routes */}
            <Route index element={<Dashboard />} />
            <Route path="/statistic" element={<Statistic />} />
            <Route path="/customize" element={<Custumize />} />
            <Route path="/userschool" element={<UserSelectSchool />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/adduser" element={<NewUser />} />
          </Route>

          {/* ... Other routes ... */}
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/selectschool" element={<SelectSchool />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

// const App = ({ history }) => {
//   return (
//     <BrowserRouter history={history}>
//      <Layout>
//        <Routes>
//          <Route element={<ProtectedRoutes />}>
//            <Route path="/" exact element={<Dashboard />} />
//            <Route path="/statistic" element={<Statistic />} />
//            <Route path="/customize" element={<Custumize />} />
//            <Route path="/userschool" element={<UserSelectSchool />} />
//            <Route path="/profile" element={<UserProfile />} />
//            <Route path="/adduser" element={<NewUser />} />
//          </Route>
//          <Route path="*" element={<Navigate to="/" replace />} />
//          <Route path="/login" element={<Login />} />
//          <Route path="/selectschool" element={<SelectSchool />} />
//        </Routes>
//      </Layout>
//     </BrowserRouter>
//   );
// };

export default App;
