import { Navigate, Outlet } from "react-router-dom";
import LoadingIndicator from "./components/sss";


// export default function ProtectedRoutes() {
//   const accessToken = localStorage.getItem("access_Token");
//   const isLoading = accessToken === null;

//   // if (isLoading) {
//   //   // Return a loading indicator or redirect to a splash/loading page
//   //   return <LoadingIndicator />;
//   // }

//   let auth = { token: accessToken };
//   return auth.token !== undefined ? (
//     <Outlet />
//   ) : (
//     <Navigate to="/login"  />
//   );
// }

// https://cbi-analytics.nl/react-js-create-protected-routes-in-react-router-6/

export default function ProtectedRoutes() {
  const accessToken = localStorage.getItem("access_Token");
  let auth = { token: accessToken };
  return auth.token ? <Outlet /> : <Navigate to="/login" />;
}
