import apiClient from "../axios";

export async function fetchStatisticData(data) {
  // console.log("fetch Statistic Data");
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient
        .get(`/statistic/${data.payload}`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    // console.log(`Fetch Statistic Data ${error}`);
  }
}
