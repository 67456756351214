import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createHashHistory } from "history";

import "./index.css";
import App from "./App";
// import { StyledChart } from "./components/chart";

import store from "./redux/store";

import * as serviceWorker from "./serviceWorker";

// middlewared
const history = createHashHistory();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <React.Fragment>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </React.Fragment>
 </React.StrictMode>,
);
serviceWorker.unregister();
