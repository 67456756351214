import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Avatar,
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";

import Logo from "../../assets/images/logo/egat.png";
import Smart from "../../assets/images/logo/smart.png";
import Sc2 from "../../assets/images/logo/sc2.png";

import kkr from "../../assets/images/logo/kkr.jpg";
import TPP from "../../assets/images/logo/TPP.jpg";

import Avt1 from "../../assets/images/avatar/AdminAvata.png";

const image_sx = () => ({
  width: {
    xs: "90%",
    sm: "90%",
    md: "90%",
    lg: "auto",
  },
  height: {
    xs: "90%",
    sm: "90%",
    md: "90%",
    lg: "auto",
  },
});

const Topbar = () => {
  const { userData } = useSelector((store) => store);
  const { username = "", role = "", userRole = [] } = userData;

  const [isAdmin, setAdmin] = useState(false);

  const [isLogin, setLogin] = useState(false);
  const [isSchoolID, setSchoolID] = useState("0");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const matches_1024 = useMediaQuery(
    json2mq({
      minWidth: 1025,
    })
  );

  const matches_700 = useMediaQuery(
    json2mq({
      minWidth: 700,
    })
  );

  // const handleUserSelectSchool = () => {
  //   navigate("/userschool");
  // };

  const handleUserProfile = () => {
    navigate("/profile");
  };

  const handleAddNewUser = () => {
    navigate("/adduser");
  };

  const handleLogout = () => {
    localStorage.removeItem("access_Token");
    localStorage.removeItem("school_ID");
    localStorage.removeItem("user_ID");
    window.location.reload(false);
    navigate("/");
  };

  useEffect(() => {
    const access_Token = localStorage.getItem("access_Token");
    access_Token ? setLogin(true) : setLogin(false);

    const school_id = localStorage.getItem("school_ID");
    setSchoolID(school_id);

    // Find the object with the specified school_id
    const result = userRole.find(
      (item) => item.school_id * 1 === school_id * 1
    );
    const outputType = result ? result.type : null;

    if (outputType === "admin") {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }, [userData, userRole]);

  return (
    <Box
      backgroundColor="#fff"
      display="flex"
      justifyContent="space-between"
      // width="100%"
      position="relative"
    >
      <Box display="flex" justifyContent="left">
        <Box
          display="flex"
          /* top | right | bottom | left */
          margin="22px 40px 23px 32px"
          alignItems="center"
        >
          <Box
            marginLeft="12px"
            component="img"
            maxWidth="112.06px"
            maxHeight="40px"
            sx={image_sx}
            src={Logo}
          />
          <Box
            marginLeft="21px"
            display="flex"
            component="img"
            maxWidth="112.06px"
            maxHeight="30px"
            sx={image_sx}
            src={Smart}
          />
          {matches_700 && isLogin ? (
            <>
              <Box
                marginLeft="21px"
                display="flex"
                component="img"
                maxWidth="42px"
                maxHeight="40px"
                sx={image_sx}
                src={isSchoolID === "1" ? TPP : isSchoolID === "2" ? kkr : ""}
              />
              <Box
                marginLeft="21px"
                display="flex"
                component="img"
                maxWidth="36.95px"
                maxHeight="40px"
                sx={image_sx}
                src={Sc2}
              />
            </>
          ) : (
            ""
          )}

          <Stack
            marginLeft="40px"
            spacing={0.2}
            sx={{ flexWrap: "wrap", gap: 0.7 }}
          >
            {matches_1024 && isLogin ? (
              <Typography
                // variant="h3"
                sx={{
                  color: "#11293C",
                  fontSize: {
                    xs: 5,
                    sm: 10,
                    md: 10,
                    lg: 14,
                  },
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontFamily: "Kanit",
                }}
              >
                {isSchoolID === "1"
                  ? "การใช้ไฟฟ้าในห้องเรียนสีเขียวจากโซลาร์เซลล์ของ โรงเรียนทองผาภูมิวิทยา"
                  : isSchoolID === "2"
                  ? "การใช้ไฟฟ้าในห้องเรียนสีเขียวจากโซลาร์เซลล์ของ โรงเรียนบ้านบนเขาแก่งเรียง"
                  : ""}
              </Typography>
            ) : (
              ""
              // <Typography
              //   // variant="h3"
              //   sx={{
              //     color: "#11293C",
              //     fontSize: {
              //       xs: 5,
              //       sm: 10,
              //       md: 10,
              //       lg: 14,
              //     },
              //     fontStyle: "normal",
              //     fontWeight: 600,
              //     fontFamily: "Kanit",
              //   }}
              // >
              //   โรงเรียนสามเงาวิทยาคม
              // </Typography>
            )}
            {matches_1024 && isLogin ? (
              <Typography
                // variant="h3"
                sx={{
                  color: "#788592",
                  fontSize: {
                    xs: 5,
                    sm: 10,
                    md: 10,
                    lg: 14,
                  },
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "normal",
                  fontFamily: "Kanit",
                }}
              >
                The amount of Electricity, Generate by Solar Cells, Consumed in
                Smart Green Learning Room
              </Typography>
            ) : (
              ""
            )}
          </Stack>
        </Box>
      </Box>
      {matches_700 && isLogin ? (
        <Box display="flex" alignItems="center">
          <Avatar
            alt="Remy Sharp"
            sx={{
              width: 48,
              height: 48,
            }}
            src={Avt1}
          />
          <Stack
            marginLeft="24px"
            spacing={0.2}
            sx={{ flexWrap: "wrap", gap: 0.7 }}
          >
            <Typography
              // variant="h3"
              sx={{
                color: "#11293C",
                fontSize: {
                  xs: 5,
                  sm: 10,
                  md: 10,
                  lg: 14,
                },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                fontFamily: "Kanit",
              }}
            >
              {username}
            </Typography>
            <Typography
              // variant="h3"
              sx={{
                color: "#788592",
                fontSize: {
                  xs: 5,
                  sm: 10,
                  md: 10,
                  lg: 14,
                },
                fontStyle: "normal",
                fontWeight: 300,
                lineHeight: "normal",
                fontFamily: "Kanit",
              }}
            >
              {role}
            </Typography>
          </Stack>
        </Box>
      ) : (
        ""
      )}

      {isLogin ? (
        <>
          <Box display="flex" alignItems="center" marginRight="32px">
            <IconButton onClick={handleOpenUserMenu}>
              <MenuIcon sx={{ width: 40, height: 40 }} />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                border: "1px solid var(--grey-2, #DEEAF7)",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "var(--white, #FFF)",
                  // transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
                width: "215px",
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* <MenuItem onClick={handleUserSelectSchool} sx={{ mb: "10px" }}>
              <ListItemIcon>
                <HomeWorkIcon fontSize="medium" />
              </ListItemIcon>
              เลือกโรงเรียน
            </MenuItem> */}
            <MenuItem onClick={handleUserProfile} sx={{ mb: "10px" }}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="medium" />
              </ListItemIcon>
              ข้อมูลส่วนตัว
            </MenuItem>
            {isAdmin ? (
              <MenuItem onClick={handleAddNewUser} sx={{ mb: "10px" }}>
                <ListItemIcon>
                  <SupervisorAccountIcon fontSize="medium" />
                </ListItemIcon>
                ตั้งค่าผู้ใช้งาน
              </MenuItem>
            ) : (
              ""
            )}

            <MenuItem onClick={handleLogout} sx={{ mb: "10px" }}>
              <ListItemIcon>
                <Logout fontSize="medium" />
              </ListItemIcon>
              ออกจากระบบ
            </MenuItem>
          </Menu>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Topbar;
