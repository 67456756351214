import axios from "axios";

const apiClient = axios.create({
  // baseURL: "https://49.0.65.108:8000/",
  baseURL: "https://smartschool.bluebems.com:8000",
});

// apiClient.interceptors.request.use((request) => {
//   const token = localStorage.getItem("access_Token");
//   if (token) {
//     request.headers = { access_Token: token };
//   }

//   return request;
// });

// apiClient.interceptors.response.use(undefined, (error) => {
//   const { response } = error;
//   if (response) {
//     const { data, status } = response;
//   }
// });

export default apiClient;
