import apiClient from "../axios";

export async function fetchSchoolHWData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient
        .get(`/school/${data.payload}`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
  }
}

export async function setControlHWData(data) {
  const { topic = "", payload = "", school_id = "0" } = data.payload;

  const token = localStorage.getItem("access_Token");
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (data.payload.code === undefined) {
    return apiClient
      .post(
        "/pub",
        {
          parameter: {
            data: {
              topic: topic,
              payload: payload,
              school_id: school_id,
            },
          },
        },
        header
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}
