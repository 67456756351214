import apiClient from "../axios";
import axios from "axios";

const apiClient8080 = axios.create({
  // baseURL: "https://49.0.65.108:8080/",
  baseURL: "https://smartschool.bluebems.com:8080",
});

export async function fetchSmartDashboardData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient
        .get(`/energy/${data.payload}`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          // console.log(`Fetch smart dashboard power ${err}`);
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
    // console.log(`Fetch smart dashboard power ${error}`);
  }
}

export async function fetchSolaLoadDashboardData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient8080
        .get(`/ws_solar_load/${data.payload}/ws`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
    // console.log(`Fetch sola load dashboard power ${error}`);
  }
}

export async function fetchSmartLightDashboardData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient8080
        .get(`/ws_smart_light/${data.payload}/ws`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
    // console.log(`Fetch smart light dashboard power ${error}`);
  }
}

export async function fetchSmartAirDashboardData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient8080
        .get(`/ws_smart_aircondition/${data.payload}/ws`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
    // console.log(`Fetch smart air dashboard power ${error}`);
  }
}

export async function fetchSmartPlugDashboardData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient8080
        .get(`/ws_smart_plug/${data.payload}/ws`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
    // console.log(`Fetch smart air dashboard power ${error}`);
  }
}
