import apiClient from "../axios";

export async function login(data) {
  const {
    isUsername = "",
    isPassword = "",
    selectSchoolID = "0",
  } = data.payload;

  return apiClient
    .post("/login", {
      parameter: {
        data: {
          username: isUsername,
          password: isPassword,
          school_id: toString(selectSchoolID),
        },
      },
    })
    .then((response) => {
      const message = response.data.message;
      if (message === "success login!") {
        const token = response.data.result.access_token;
        const userID = response.data.result.user_id;
        localStorage.setItem("access_Token", token);
        localStorage.setItem("school_ID", selectSchoolID);
        localStorage.setItem("user_ID", userID);
      }

      return response;
    })
    .catch((err) => {
      throw Error(err);
    });
}

export async function logout() {
  localStorage.removeItem("access_Token");
  localStorage.removeItem("school_ID");
  localStorage.removeItem("user_ID");
  return true;
}

export async function fetchUserData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient
        .get(`/users/${data.payload}`, header)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
  }
}

export async function setUserInfoData(data) {
  try {
    const { isUsername = "", isName = "", roles } = data.payload;

    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient
        .put(`/users/info`, header, {
          parameter: {
            data: {
              username: isUsername,
              name: isName,
              img: "",
              roles: `${roles}`,
            },
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
  }
}

export async function setNewPasswordData(data) {
  const { userID = "", oldPassword = "", isNewPassword = "" } = data.payload;

  const token = localStorage.getItem("access_Token");

  if (data.payload.data === undefined) {
    return apiClient
      .put(
        `/users/password`,
        {
          parameter: {
            data: {
              id: userID,
              old_password: oldPassword,
              password: isNewPassword,
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}

export async function setNewInfoData(data) {
  const { isName = "", roles = "" } = data.payload;
  const jsonString = JSON.stringify(roles);
  const token = localStorage.getItem("access_Token");
  const userID = localStorage.getItem("user_ID");

  const _data = {
    name: isName,
    id: userID.toString(),
    roles: jsonString,
  };

  if (data.payload.data === undefined) {
    return apiClient
      .put(
        `/users/info`,
        {
          parameter: {
            data: _data,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}

export async function addNewUserData(data) {
  const { username = "", name = "", password = "", roles = "" } = data.payload;
  const token = localStorage.getItem("access_Token");

  const _data = {
    username: username,
    password: password,
    name: name,
    roles: roles,
  };
  if (data.payload.data === undefined) {
    return apiClient
      .post(
        `/users/insert`,
        {
          parameter: {
            data: _data,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}

export async function deleteUserData(data) {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (data.payload.code === undefined) {
      return apiClient
        .delete(`/users/${data.payload}`, header)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw Error(err);
        });
    }
  } catch (error) {
    throw Error(error);
  }
}

export async function editWorkerViewerInfoData(data) {
  const { isID = "", isName = "", roles = "" } = data.payload;
  const jsonString = JSON.stringify(roles);
  const token = localStorage.getItem("access_Token");

  const _data = {
    name: isName,
    id: isID.toString(),
    roles: jsonString,
  };
  if (data.payload.data === undefined) {
    return apiClient
      .put(
        `/users/info`,
        {
          parameter: {
            data: _data,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}

// List all users in systems
export async function fetchListUsersData() {
  try {
    const token = localStorage.getItem("access_Token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return apiClient
      .get(`/users`, header)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw Error(err);
      });
  } catch (error) {
    throw Error(error);
  }
}
