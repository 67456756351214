import React from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

import Topbar from "../../../scenes/global/Topbar";
import { CssBaseline } from "@mui/material";

const fontTheme = createTheme({
  typography: {
    fontFamily: `"Kanit", sans-serif`,
  },
});

const MainLayout = ({ children }) => {
  return (
    <ThemeProvider theme={fontTheme}>
      <div className="app">
        <main className="content">
          <Topbar />
          <CssBaseline />
        </main>
      </div>
    </ThemeProvider>
  );
};

export default MainLayout;
