import { configureStore } from "@reduxjs/toolkit";

import dashboardReducer from "./dashboard";
import statictisReducer from "./statictis";
import userReduces from "./user";
import initialReducer from './initial'

import watcherSaga from "../sagas";

import createSagaMiddleware from "@redux-saga/core";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    dashboardData: dashboardReducer,
    statictisData: statictisReducer,
    userData: userReduces,
    initialData : initialReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: false }).prepend(sagaMiddleware);
  },
});

sagaMiddleware.run(watcherSaga);

export default store;
