import {
  delay,
  all,
  call,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  setStateData,
  setStateControlHWData,
  getDashboardData,
  getSolaLoadDashboardData,
  getSmartLightDashboardData,
  getSmartAirDashboardData,
  getSmartPlugDashboardData,
} from "../redux/dashboard";

import {
  setStateUserData,
  setLoginData,
  getSchoolHWData,
  getUserData,
  setNewPasswordUserData,
  setNewInfodUserData,
  addNewUsersData,
  getListUserData,
  removeUserData
} from "../redux/user";

import { setInitialStateData, setStateSchoolsData } from "../redux/initial";

import { setStateStatisticData, getStatictisData } from "../redux/statictis";

import {
  fetchSmartDashboardData,
  fetchSolaLoadDashboardData,
  fetchSmartLightDashboardData,
  fetchSmartAirDashboardData,
  fetchSmartPlugDashboardData,
} from "../services/dashboard";

import {
  login,
  fetchUserData,
  setNewPasswordData,
  setNewInfoData,
  addNewUserData,
  fetchListUsersData,
  deleteUserData
} from "../services/auth";

import { fetchSchoolsData } from "../services/getSchools";

import { fetchSchoolHWData, setControlHWData } from "../services/controlHW";

import { fetchStatisticData } from "../services/statistic";

function* getSmartDashboardAction(data) {
  try {
    yield delay(300);

    yield put(getDashboardData(yield call(fetchSmartDashboardData, data)));
  } catch (e) {
    // console.log("error getSmartDashboardAction");
    // console.log(e);
  }
}

function* getSolaLoadDashboardAction(data) {
  try {
    yield delay(300);

    yield put(
      getSolaLoadDashboardData(yield call(fetchSolaLoadDashboardData, data))
    );
  } catch (e) {
    // console.log("error getSolaLoadDashboardAction");
    // console.log(e);
  }
}

function* getSmartLightDashboardAction(data) {
  try {
    yield delay(300);

    yield put(
      getSmartLightDashboardData(yield call(fetchSmartLightDashboardData, data))
    );
  } catch (error) {
    // console.error("Error in getSmartLightDashboardAction:", error);
    // console.log("error getSmartLightDashboardAction");
    // console.log(e);
  }
}

function* getSmartAirDashboardAction(data) {
  try {
    yield delay(300);

    yield put(
      getSmartAirDashboardData(yield call(fetchSmartAirDashboardData, data))
    );
  } catch (e) {
    // console.log("error getSmartAirDashboardAction");
    // console.log(e);
  }
}

function* getSmartPlugDashboardAction(data) {
  try {
    yield delay(300);

    yield put(
      getSmartPlugDashboardData(yield call(fetchSmartPlugDashboardData, data))
    );
  } catch (e) {
    // console.log("error getSmartPlugDashboardAction");
    // console.log(e);
  }
}

function* logInAction(data) {
  try {
    yield delay(300);

    yield put(setLoginData(yield call(login, data)));
  } catch (e) {
    // console.log("error logInAction");
    // console.log(e);
  }
}

function* getUserInfoAction(data) {
  try {
    yield delay(300);

    yield put(getUserData(yield call(fetchUserData, data)));
  } catch (e) {
    // console.log("error getUserInfoAction");
    // console.log(e);
  }
}

function* putNewPasswordUserAction(data) {
  try {
    yield delay(300);

    yield put(setNewPasswordUserData(yield call(setNewPasswordData, data)));
  } catch (error) {}
}

function* putNewInfoUserAction(data) {
  try {
    yield delay(300);

    yield put(setNewInfodUserData(yield call(setNewInfoData, data)));
  } catch (error) {}
}

function* addNewUsersAction(data) {
  try {
    yield delay(300);

    yield put(addNewUsersData(yield call(addNewUserData, data)));
  } catch (error) {}
}

function* getSchoolAction() {
  try {
    yield delay(300);

    yield put(setStateSchoolsData(yield call(fetchSchoolsData)));
  } catch (e) {
    // console.log("error getSchoolAction");
    // console.log(e);
  }
}

function* getSchoolHWAction(data) {
  try {
    yield delay(300);
    yield put(getSchoolHWData(yield call(fetchSchoolHWData, data)));
  } catch (e) {
    // console.log("error getSchoolHWAction");
    // console.log(e);
  }
}

function* setControlHWAction(data) {
  try {
    yield delay(300);

    yield put(setStateControlHWData(yield call(setControlHWData, data)));
  } catch (e) {
    // console.log("error setControlHWAction");
    // console.log(e);
  }
}

function* getStatisticAction(data) {
  try {
    yield delay(300);

    yield put(getStatictisData(yield call(fetchStatisticData, data)));
  } catch (e) {
    // console.log("error getStatisticAction");
    // console.log(e);
  }
}

function* getListUsersAction() {
  try {
    yield delay(300);

    yield put(getListUserData(yield call(fetchListUsersData)));
  } catch (e) {
    // console.log("error getSchoolAction");
    // console.log(e);
  }
}

function* deleteUserAction(data) {
  try {
    yield delay(300);

    yield put(removeUserData(yield call(deleteUserData, data)));
  } catch (e) {
    // console.log("error getStatisticAction");
    // console.log(e);
  }
}

export default function* watcherSaga() {
  yield all([
    takeLatest(setStateData.type, getSmartDashboardAction),
    takeLatest(setStateData.type, getSolaLoadDashboardAction),
    takeLatest(setStateData.type, getSmartLightDashboardAction),
    takeLatest(setStateData.type, getSmartAirDashboardAction),
    takeLatest(setStateData.type, getSmartPlugDashboardAction),
    takeLatest(setStateControlHWData.type, setControlHWAction),
    takeLatest(setStateUserData.type, logInAction),
    takeLatest(getSchoolHWData.type, getSchoolHWAction),
    takeLatest(setInitialStateData.type, getSchoolAction),
    takeLatest(getUserData.type, getUserInfoAction),
    takeLatest(setNewPasswordUserData.type, putNewPasswordUserAction),
    takeLatest(setNewInfodUserData.type, putNewInfoUserAction),
    takeLatest(setStateStatisticData.type, getStatisticAction),
    takeLatest(addNewUsersData.type, addNewUsersAction),
    takeLatest(getListUserData.type, getListUsersAction),
    takeLatest(removeUserData.type, deleteUserAction),
  ]);
}
