import apiClient from "../axios";

export async function fetchSchoolsData() {
  return apiClient
    .get("/schools")
    .then((response) => {
      // console.log("response")
      // console.log(response)
      if (response.data.result!== undefined && response.data.result) {
        return response.data.result;
      }
    })
    .catch((err) => {
      throw Error(err);
    });
}
