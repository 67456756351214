import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isUserAuthorized: false,
  isUserSelectSchool: false,
  userInfo: {},
  userRole: [],
  access_token: "",
  token_type: "",
  userID: 0,
  userSchool: [],
  rowUserSchool: [],
  code: "",
  smart_plugs: [],
  smart_light_blub: [],
  air_conditioner: [],
  other: [],
  solar_cell: [],
  load_center: [],
  username: "",
  name: "",
  _code: 0,
  _status: 0,
  _message: "",
  listUser: [],
};

const userDataSlice = createSlice({
  name: "userDataSlice",
  initialState,
  reducers: {
    setStateUserData: (state) => {
      return { ...state, isLoading: true };
    },
    setStateSelectSchoolData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isUserSelectSchool: action.payload.isDay,
      };
    },
    setLoginData: (state, action) => {
      if (
        action.payload !== undefined &&
        action.payload.data.code === 200 &&
        action.payload.status === 200
      ) {
        const { status = 0, code = 0 } = action.payload.data;

        return {
          ...state,
          isLoading: false,
          username: "",
          name: "",
          access_token: "",
          token_type: "",
          role: "",
          schools: [],
          _code: code,
          _status: status,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          username: "",
          name: "",
          access_token: "",
          token_type: "",
          role: "",
          schools: [],
          _code: action.payload.data.code * 1,
          _status: action.payload.status,
        };
      }
    },
    getSchoolHWData: (state, action) => {
      if (
        action.payload &&
        action.payload.result &&
        action.payload.result.length > 0
      ) {
        return {
          ...state,
          isLoading: false,
          smart_plugs: action.payload.result.filter(
            (item) => item.type === "smart_plugs"
          ),
          smart_light_blub: action.payload.result.filter(
            (item) => item.type === "smart_light_blub"
          ),
          air_conditioner: action.payload.result.filter(
            (item) => item.type === "air_conditioner"
          ),
          other: action.payload.result.filter((item) => item.type === "other"),
          solar_cell: action.payload.result.filter(
            (item) => item.type === "solar_cell"
          ),
          load_center: action.payload.result.filter(
            (item) => item.type === "load_center"
          ),
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
    getUserData: (state, action) => {
      if (action.payload) {
        if (
          action.payload !== undefined &&
          action.payload.result &&
          action.payload.code &&
          action.payload.code === "200"
        ) {
          const { _school = [], _user_role = [] } = action.payload.result;
          const schoolMap = {};
          _school.forEach((school, index) => {
            schoolMap[index + 1] = school;
          });
          const result = _user_role.map((item) => ({
            id: item.school_id,
            school: schoolMap[item.school_id],
            type: item.type,
          }));
          return {
            ...state,
            isLoading: false,
            userInfo: action.payload.result._user_info,
            userRole: action.payload.result._user_role,
            userSchool: action.payload.result._school,
            username: action.payload.result._user_info.username,
            name: action.payload.result._user_info.name,
            rowUserSchool: result,
          };
        } else {
          return {
            ...state,
            isLoading: false,
          };
        }
      }
    },
    setNewPasswordUserData: (state, action) => {
      if (action.payload.data !== undefined) {
        const { status = "", code = "" } = action.payload.data;
        return { ...state, isLoading: false, _code: code, _status: status };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
    setNewInfodUserData: (state, action) => {
      if (action.payload.data !== undefined) {
        const { status = "", code = "" } = action.payload.data;
        if (code === "200" && status === "Ok") {
          return {
            ...state,
            isLoading: false,
            _code: code,
            _status: status,
          };
        }

        if (code === "500") {
          return {
            ...state,
            isLoading: false,
            _code: code,
            _status: status,
          };
        }
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
    updateCodeStatusData: (state, action) => {
      return {
        ...state,
        _code: action.payload._code,
        _status: action.payload._status,
      };
    },
    addNewUsersData: (state, action) => {
      if (action.payload.data !== undefined) {
        const { status = "", code = "", message = "" } = action.payload.data;
        return {
          ...state,
          isLoading: false,
          _code: code,
          _status: status,
          _message: message,
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
    getListUserData: (state, action) => {
      if (action.payload) {
        if (
          action.payload !== undefined &&
          action.payload.result &&
          action.payload.code &&
          action.payload.code === "200"
        ) {
          const { result = [] } = action.payload;

          // Sort the array by id
          const sortedData = result
            .sort((a, b) => a.id - b.id)
            .filter((item) => item.id !== 2);

          return {
            ...state,
            isLoading: false,
            listUser: sortedData,
          };
        } else {
          return {
            ...state,
            isLoading: false,
          };
        }
      }
    },
    removeUserData: (state, action) => {
      if (action.payload.data !== undefined) {
        const { code = "" } = action.payload.data;
        return {
          ...state,
          isLoading: false,
          _code: code,
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
  },
});

export const {
  setStateUserData,
  setStateSelectSchoolData,
  setLoginData,
  getSchoolHWData,
  getUserData,
  setNewPasswordUserData,
  setNewInfodUserData,
  updateCodeStatusData,
  addNewUsersData,
  getListUserData,
  removeUserData,
} = userDataSlice.actions;

export default userDataSlice.reducer;
