// import React from "react";
// import { Navigate } from "react-router-dom";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { useSelector } from "react-redux";

// import LoadingIndicator from "../sss";
// import Topbar from "../../scenes/global/Topbar";
// import { CssBaseline } from "@mui/material";

// import AuthLayout from "./Auth";
// import MainLayout from "./Main";

// const Layouts = {
//   auth: AuthLayout,
//   main: MainLayout,
// };

// const fontTheme = createTheme({
//   typography: {
//     fontFamily: `"Kanit", sans-serif`,
//   },
// });

// // const Layout = ({ children }) => {
// //   // console.log(children);
// //   const { userData } = useSelector((store) => store);
// //   const { isLoading = false, isUserAuthorized, isUserSelectSchool } = userData;

// //   const CheckLayout = () => {
// //     if (isLoading && !isUserAuthorized && !isUserSelectSchool) {
// //       return null;
// //     }

// //     if (!isUserAuthorized && !isUserSelectSchool) {
// //       // <Navigate to="/dashboard" replace={true} />
// //       return <AuthLayout children />;
// //       //return <Navigate to="/login" replace={true} />;
// //     }
// //     return children;
// //   };

// //   return (
// //     // <>{CheckLayout()}</>
// //     <ThemeProvider theme={fontTheme}>
// //       <div className="app">
// //         <main className="content">
// //           <Topbar />
// //           <CssBaseline />
// //           {children}
// //           {/* {CheckLayout()} */}
// //         </main>
// //       </div>
// //     </ThemeProvider>
// //   );
// // };

// // export default Layout;

// const Layout = ({ children }) => {
//   const { userData } = useSelector((store) => store);
//   const { isLoading, isUserAuthorized, isUserSelectSchool } = userData;

//   const CheckLayout = () => {
//     if (isLoading) {
//       // You can render a loading indicator here
//       return <LoadingIndicator />;
//     }

//     if (!isUserAuthorized && !isUserSelectSchool) {
//       // Render a component indicating the user is not authorized or hasn't selected a school
//       return <Navigate to="/login" state={{ from: window.location.pathname }} />
//       // return <UnauthorizedLayout />;
//     }

//     return children;
//   };

//   return (
//     <ThemeProvider theme={fontTheme}>
//       <div className="app">
//         <main className="content">
//           <Topbar />
//           <CssBaseline />
//           {/* <CheckLayout /> */}
//         </main>
//       </div>
//     </ThemeProvider>
//   );
// };

// export default Layout;

import React from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

import LoadingIndicator from "../sss";
import Topbar from "../../scenes/global/Topbar";
import { CssBaseline } from "@mui/material";

import AuthLayout from "./Auth";
import MainLayout from "./Main";

const Layouts = {
  auth: AuthLayout,
  main: MainLayout,
};

const fontTheme = createTheme({
  typography: {
    fontFamily: `"Kanit", sans-serif`,
  },
});

const Layout = ({ children }) => {
  // console.log(children);
  const { userData } = useSelector((store) => store);
  const { isLoading = false, isUserAuthorized, isUserSelectSchool } = userData;

  const CheckLayout = () => {
    if (isLoading && !isUserAuthorized && !isUserSelectSchool) {
      return <LoadingIndicator />;
    }

    if (!isUserAuthorized && !isUserSelectSchool) {
      // <Navigate to="/dashboard" replace={true} />
      return <AuthLayout children />;
      //return <Navigate to="/login" replace={true} />;
    }
    return children;
  };

  return (
    // <>{CheckLayout()}</>
    <ThemeProvider theme={fontTheme}>
      <div className="app">
        <main className="content">
          <Topbar />
          <CssBaseline />
          {children}
          {/* {CheckLayout()} */}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
